var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
		'c-block-list-news-list-custom',
		'c-block-list-news-list-custom--solid',
		'c-block-list-news-list-custom--wide',
		'px-layout-margin py-lg -mx-layout-margin mb-0',
		'<768:pb-xl <768:pt-lg',

		{
			'bg-sand': _vm.template !== 'FrontPage',
		} ]},[(_vm.title)?_c('BaseH2',{staticClass:"text-primary-button mb-sm",domProps:{"textContent":_vm._s(_vm.title)}}):_vm._e(),_vm._v(" "),_c('SwiperWrap',{staticClass:"\n\t\t\tc-block-list-news-list-custom__swiper\n\t\t\trelative\n\t\t\t-mx-layout-margin\n\t\t",attrs:{"move-by":1,"items-wrapper-class-names":[
			'c-block-list-news-list-custom__scroller',
			'flex w-full u-hide-scrollbar',
			'snap snap-x snap-mandatory',
			'space-x-layout-gutter overflow-x-auto',
			'px-layout-margin pb-sm',
			'snap-px-layout-margin' ]},scopedSlots:_vm._u([{key:"items",fn:function(){return _vm._l((_vm.items),function(item,index){return _c('ContentCard',{key:("news-" + index),class:[
					'c-block-list-news-list-custom__item',
					'flex-shrink-0 w-4col >=768:w-6col >=1024:w-4col >=1440:w-3col',
					'snap-start' ],attrs:{"name":item.title,"to":item.url,"target":item.target,"image":Object.assign({}, (item.image || {}),
					{aspectRatio: 1.5}),"light":""},scopedSlots:_vm._u([{key:"after",fn:function(){return [_c('div',{staticClass:"mt-sm",domProps:{"textContent":_vm._s(_vm.formatDate(item.newsDate))}})]},proxy:true}],null,true)})})},proxy:true},{key:"before",fn:function(ref){
				var showPagination = ref.showPagination;
				var go = ref.go;
return [_c('div',{class:[
					'absolute -top-md right-layout-margin',
					'transform -translate-y-full',
					'flex items-end  <768:hidden' ]},[(_vm.archive)?_c('NuxtLinkExt',{staticClass:"\n\t\t\t\t\t\tc-block-list-news-list-custom__all-news\n\t\t\t\t\t\tuppercase\n\t\t\t\t\t\tfont-semibold\n\t\t\t\t\t\ttext-h7\n\t\t\t\t\t\tmb-2\n\t\t\t\t\t\tmr-md\n\t\t\t\t\t",attrs:{"to":_vm.archive.url,"target":_vm.archive.target},domProps:{"textContent":_vm._s(_vm.titles.seeAllTitle)}}):_vm._e(),_vm._v(" "),_c('button',{class:[
						'p-8 mr-xs',
						'duration-300 ease-smooth-out',
						'text-text hover:text-primary-button',
						{ 'opacity-20': !showPagination.previous } ],attrs:{"aria-disabled":!showPagination.previous,"aria-label":"Gå til forrige nyhed"},on:{"click":function () { return go.previous(false); }}},[_c('SvgCaret',{staticClass:"w-12 transform rotate-180"})],1),_vm._v(" "),_c('button',{class:[
						'p-8',
						'duration-300 ease-smooth-out',
						'text-text hover:text-primary-button',
						{ 'opacity-20': !showPagination.next } ],attrs:{"aria-disabled":!showPagination.next,"aria-label":"Gå til næste nyhed"},on:{"click":function () { return go.next(false); }}},[_c('SvgCaret',{staticClass:"w-12"})],1)],1)]}}])}),_vm._v(" "),(_vm.archive)?_c('NuxtLinkExt',{staticClass:"block uppercase font-semibold text-body mt-md >=768:hidden",attrs:{"to":_vm.archive.url,"target":_vm.archive.target},domProps:{"textContent":_vm._s(_vm.titles.seeAllTitle)}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }