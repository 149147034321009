<template>
	<div
		:class="[
			'c-block-list-news-list-custom',
			'c-block-list-news-list-custom--solid',
			'c-block-list-news-list-custom--wide',
			'px-layout-margin py-lg -mx-layout-margin mb-0',
			'<768:pb-xl <768:pt-lg',

			{
				'bg-sand': template !== 'FrontPage',
			},
		]"
	>
		<BaseH2 v-if="title" class="text-primary-button mb-sm" v-text="title" />

		<SwiperWrap
			:move-by="1"
			class="
				c-block-list-news-list-custom__swiper
				relative
				-mx-layout-margin
			"
			:items-wrapper-class-names="[
				'c-block-list-news-list-custom__scroller',
				'flex w-full u-hide-scrollbar',
				'snap snap-x snap-mandatory',
				'space-x-layout-gutter overflow-x-auto',
				'px-layout-margin pb-sm',
				'snap-px-layout-margin',
			]"
		>
			<!-- Items -->
			<template #items>
				<ContentCard
					v-for="(item, index) in items"
					:key="`news-${index}`"
					:name="item.title"
					:to="item.url"
					:target="item.target"
					:class="[
						'c-block-list-news-list-custom__item',
						'flex-shrink-0 w-4col >=768:w-6col >=1024:w-4col >=1440:w-3col',
						'snap-start',
					]"
					:image="{
						...(item.image || {}),
						aspectRatio: 1.5,
					}"
					light
				>
					<template #after>
						<div
							class="mt-sm"
							v-text="formatDate(item.newsDate)"
						></div>
					</template>
				</ContentCard>
			</template>

			<!-- Pagination -->
			<template #before="{ showPagination, go }">
				<div
					:class="[
						'absolute -top-md right-layout-margin',
						'transform -translate-y-full',
						'flex items-end  <768:hidden',
					]"
				>
					<!-- All News -->
					<NuxtLinkExt
						v-if="archive"
						:to="archive.url"
						:target="archive.target"
						class="
							c-block-list-news-list-custom__all-news
							uppercase
							font-semibold
							text-h7
							mb-2
							mr-md
						"
						v-text="titles.seeAllTitle"
					/>

					<!-- Previous -->
					<button
						:aria-disabled="!showPagination.previous"
						aria-label="Gå til forrige nyhed"
						:class="[
							'p-8 mr-xs',
							'duration-300 ease-smooth-out',
							'text-text hover:text-primary-button',
							{ 'opacity-20': !showPagination.previous },
						]"
						@click="() => go.previous(false)"
					>
						<SvgCaret class="w-12 transform rotate-180" />
					</button>

					<!-- Next -->
					<button
						:aria-disabled="!showPagination.next"
						aria-label="Gå til næste nyhed"
						:class="[
							'p-8',
							'duration-300 ease-smooth-out',
							'text-text hover:text-primary-button',
							{ 'opacity-20': !showPagination.next },
						]"
						@click="() => go.next(false)"
					>
						<SvgCaret class="w-12" />
					</button>
				</div>
			</template>
		</SwiperWrap>

		<!-- All News -->
		<NuxtLinkExt
			v-if="archive"
			:to="archive.url"
			:target="archive.target"
			class="block uppercase font-semibold text-body mt-md >=768:hidden"
			v-text="titles.seeAllTitle"
		/>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import SwiperWrap from '~/citi-baseline/components/SwiperWrap';
import ContentCard from '~/components/shared/ContentCard';
import SvgCaret from '~/assets/svgs/caret-icon.svg?inline';

export default {
	name: 'BlockListNewsListCustom',
	components: { SwiperWrap, ContentCard, SvgCaret },
	inheritAttrs: false,

	props: {
		title: {
			type: String,
			required: false,
		},
		items: {
			type: Array,
			default: () => [],
		},
		archive: {
			type: Object,
			required: false,
		},
		titles: {
			type: Object,
			required: false,
		},
	},

	computed: mapState({
		template: (state) => state.template,
	}),

	methods: {
		formatDate(dateString) {
			const date = new Date(dateString);

			const day = `0${date.getDate()}`.slice(-2);
			const month = `0${date.getMonth() + 1}`.slice(-2);
			const year = date.getFullYear();

			return `${day}.${month}.${year}`;
		},
	},
};
</script>

<style lang="postcss">
.c-block-list-news-list-custom__swiper {
	width: calc(100% + var(--theme-layout-margin) * 2);
}
</style>
